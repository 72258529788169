import React from 'react';
import logo from '../Assets/images/2024-08-02 New Logo copy.webp'
import pay from '../Assets/images/am_sgEYOH_o0.webp'
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-3 text-center">
                        <img src={logo} className="img-fluid" alt="Logo" style={{height:"100px", width:"150px"}}/>
                        <p className="mt-4 text-start">At the heart of our mission values, we’re here to bring your brand to elevated heights with reliable and affordable design, digital marketing, and branding services. </p>
                    </div>
                    <div className="col-md-3">
        <h6>Links</h6>
        <ul>
          <li><NavLink to="/home" activeClassName="active">Home</NavLink></li>
          <li><NavLink to="/about" activeClassName="active">About Us</NavLink></li>
          <li><NavLink to="/logobranding" activeClassName="active">Services</NavLink></li>
          <li><NavLink to="/pricing" activeClassName="active">Pricing</NavLink></li>
        </ul>
      </div>
      <div className="col-md-3">
        <h6>Services</h6>
        <ul>
          <li><NavLink to="/portfolio" activeClassName="active">Logo Design</NavLink></li>
          <li><NavLink to="/logobranding" activeClassName="active">Branding</NavLink></li>
          <li><NavLink to="/website" activeClassName="active">Website Design</NavLink></li>
          <li><NavLink to="/mobile-application" activeClassName="active">Mobile Application</NavLink></li>
          <li><NavLink to="/book-publication" activeClassName="active">Book Publication</NavLink></li>
          <li><NavLink to="/amazon-marketing" activeClassName="active">Amazon Marketing</NavLink></li>
          {/* <li><NavLink to="/video-animation" activeClassName="active">Video Animation</NavLink></li> */}
        </ul>
      </div>
                    
                    <div className="col-md-3">
                        <h6>Contact Our Team</h6>
                        <ul>
                            
                            <li><a href="tel:209-227-2277"><i className="fa-solid fa-phone-volume"></i> 209-227-2277</a></li>
                            <li><a href="mailto:sales@fortunelogos.com"><i className="fa-regular fa-envelope"></i> sales@fortunelogos.com</a></li>
                            <li>
                                <address>
                                    <i className="fa-solid fa-location-dot"></i>
                                    Address : 13151 Emily Rd Ste 200 Dallas TX 75240-8989
                                </address>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="row pt-2 pb-2 align-items-center">
                        <div className="col-md-6">
                            <h5>Copyright © 2024 Fortunelogos. All rights reserved</h5>
                        </div>
                        <div className="col-md-6 align-items-end">
                            <h5>EASY AND SECURE PAYMENT</h5>
                            <img src={pay} alt="Secure Payment" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
